.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.login-box {
  background-color: #fff;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.wl-title {
  font-size: 1.5rem;
  margin-bottom: 16px;
}

.form-group {
  margin-top: 16px;
}

.form-label {
  color: #333;
}

.form-input-control {
  margin-top: 8px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.error-message {
  color: red;
  margin-top: 4px;
}

.text-center {
  text-align: center;
}

.reset-button {
  color: #fff;
  padding: 12px;
  width: 100%;
  border-radius: 4px;
  cursor: pointer;
}

.reset-button:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.hover\:drop-shadow-xl:hover {
  filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.2));
}
