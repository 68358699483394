.login-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login-container .dashbard-logo {
  width: 220px;
  margin: 25px 0 50px;
}

.login-container .login-box {
  background: #ffffff;
  box-shadow: 0px 20px 27px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  padding: 40px 40px 20px;
  width: 520px;
  max-width: 90%;
  margin: 0 auto;
}

.login-container .login-box .wl-title {
  font-size: 20px;
  line-height: 27px;
  letter-spacing: -0.442553px;
  color: black;
  text-align: center;
}

.login-container .login-box .label-text {
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #74788d;
}

.login-container .flex-label {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.form-group {
  margin-bottom: 15px;
}
.form-label {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #252f40;
  margin: 7px 0;
  display: inline-block;
}
.form-input-control {
  border: 1px solid #e4e4e4;
  border-radius: 3px;
  width: 100%;
  padding: 10px;
}
.custom-control {
  margin-bottom: 10px;
}
.custom-control .custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.custom-control .custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
  padding-left: 25px;
}
.custom-control .custom-control-label::before {
  position: absolute;
  top: 2px;
  left: 0;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #979797 solid 2px;
  border-radius: 2px;
}
.custom-control .custom-control-label::after {
  position: absolute;
  top: 2px;
  left: 0;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: no-repeat 50%/50% 50%;
}
.custom-control .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #007bff;
  background-color: #007bff;
}
.custom-control .custom-control-input:checked ~ .custom-control-label::after {
  width: 5px;
  height: 10px;
  margin: 0px 4px;
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
  transform: rotate(45deg);
  left: 0.1em;
  top: 0.26em;
}
.login-container .btn {
  margin: 10px 0;
  border: 0;
  padding: 9px 12px;
  border-radius: 3px;
  font-size: 14px;
  line-height: 19px;
  cursor: pointer;
  text-decoration: none !important;
  min-width: 120px;
  background: #2150f7;
  color: #fff;
}
.login-container .btn:hover {
  opacity: 0.8;
}
.login-container .login-link {
  font-size: 14px;
  line-height: 16px;
  text-align: right;
  color: #979797;
  text-decoration: none;
}

.login-container .login-width {
  margin: 15px 0 30px;
}

.login-container .login-width a {
  text-decoration: none;
  margin: 0 5px;
}

.primary-link {
  color: #2150f7;
  text-decoration: none;
}
@media (max-height: 670px) {
  .login-container {
    justify-content: flex-start;
    padding-bottom: 30px;
  }
  .login-container .dashbard-logo {
    margin: 30px 0;
  }
}

@media (max-height: 767px) {
  .login-container .login-box {
    padding: 20px 30px 20px;
  }
  .login-container .dashbard-logo {
    margin-bottom: 20px;
  }
  .login-container .login-width {
    margin: 15px 0 18px;
  }
}
@media (max-width: 767px) {
  .login-box {
    padding: 20px 30px 20px;
  }
  .dashbard-logo {
    margin-bottom: 30px;
  }
}

.login-box {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.wl-title {
  font-size: 1.5rem;
  margin-bottom: 8px;
}

.label-text {
  color: #666666;
}

.form-group {
  margin-top: 16px;
}

.form-label {
  color: #777777;
}

.form-input-control {
  margin-top: 4px;
  padding: 8px;
  border: 1px solid #cccccc;
  border-radius: 4px;
}

.login-link {
  color: #007bff;
  text-decoration: none;
}

.custom-control-label {
  margin-left: 4px;
  color: #777777;
}

.text-center {
  text-align: center;
}

.error-message {
  color: red;
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.login-box {
  background-color: #fff;
  border-radius: 8px;
  padding: 24px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.wl-title {
  font-size: 24px;
  margin-bottom: 16px;
}

.label-text {
  color: #888;
}

.form-group {
  margin-top: 16px;
}

.login-link {
  color: #0366d6;
  font-size: 14px;
  text-decoration: none;
}

.password-toggle-icon {
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  cursor: pointer;
  color: #888;
}

.custom-control-input {
  margin-top: 8px;
}

.text-center {
  margin-top: 16px;
}

/* Additional styles */

.password-toggle-icon:hover {
  color: #333;
}

.password-toggle-icon svg {
  font-size: 16px;
}

.logo-container {
  margin-bottom: 1rem;
}

.wogo-logo {
  width: 250px;
  height: auto;
}
